(function () {
    'use strict';
    angular
        .module('ipmApp')
        .factory('HaqEvaluationSkipsService', HaqEvaluationSkipsService);

    HaqEvaluationSkipsService.$inject = ['$resource', '$q', 'halClient', '$http'];

    function HaqEvaluationSkipsService($resource, $q, halClient, $http) {
        var baseUrl = 'api/haq-evaluation-skips';
        var resourceUrl = 'api/haq-evaluation-skips/:id';
        var service = $resource(resourceUrl, {}, {});
        service.findAll = findAll;
        service.findById = findById;
        service.addHaqEvaluationSkip = addHaqEvaluationSkip;
        service.addBackHaqEvaluationSkip = addBackHaqEvaluationSkip;
        service.removeHaqEvaluationSkip = removeHaqEvaluationSkip;
        service.addBackMultipleHaqEvaluationSkip = addBackMultipleHaqEvaluationSkip;
        service.removeMultipleHaqEvaluationSkip = removeMultipleHaqEvaluationSkip;

        function findAll(skipFilter, pageable) {
            var deferred = $q.defer();

            var url = baseUrl;
            $http.get(url, {
                params: {
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort,
                    hideRemoved: skipFilter && skipFilter.hideRemoved ? skipFilter.hideRemoved : false
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        function findById(id) {
            var deferred = $q.defer();
            var url = baseUrl + "/" + id;
            halClient.$get(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function addHaqEvaluationSkip(skipReviewEvaluation) {
            var url = baseUrl + "/add";
            return $http.post(url, skipReviewEvaluation).then(function (response) {
                return response.data;
            });
        }

        function addBackHaqEvaluationSkip(skipReviewEvaluationAddBack) {
            var url = baseUrl + "/add-back/" + skipReviewEvaluationAddBack.id;
            return $http.post(url, skipReviewEvaluationAddBack).then(function (response) {
                return response.data;
            });
        }

        function removeHaqEvaluationSkip(id) {
            var url = baseUrl + "/" + id;
            return $http.delete(url).then(function (response) {
                return response;
            });
        }

        function addBackMultipleHaqEvaluationSkip(haqSkipsSelected) {
            var url = baseUrl + "/add-back-multiple";
            return $http.post(url, haqSkipsSelected).then(function (response) {
                return response.data;
            });
        }

        function removeMultipleHaqEvaluationSkip(haqSkipsSelected) {
            var url = baseUrl + "/remove-multiple";
            return $http.post(url, haqSkipsSelected).then(function (response) {
                return response;
            });
        }

        return service;

    }
})();
