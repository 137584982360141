(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('JhiLanguageController', JhiLanguageController);

    JhiLanguageController.$inject = ['$translate', 'JhiLanguageService', 'tmhDynamicLocale', '$rootScope', 'Principal', 'User', 'ipmConstants', '$window', '$state'];

    function JhiLanguageController ($translate, JhiLanguageService, tmhDynamicLocale, $rootScope, Principal, User, constants, $window, $state) {
        var vm = this;

        vm.changeLanguage = changeLanguage;
        vm.languages = null;
        vm.currentLanguage = 'en';

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function changeLanguage (languageKey) {
            $translate.use(languageKey);
            tmhDynamicLocale.set(languageKey);
            vm.currentLanguage = languageKey;
            if(Principal.hasAnyAuthority([constants.ROLE_PATIENT]) === true) {
                JhiLanguageService.updateLangKey().then(function (resource) {
                    if($state.includes('survey-workflow')) {
                        $window.location.reload();
                    }
                });
            }
            $rootScope.$emit("changeLanguageCustom", {langKey: vm.currentLanguage});
        }
        
        $rootScope.$on("changeLanguage", function(event, args){
            if(args.langKey) {
                changeLanguage(args.langKey);
            }
        });
        
        $rootScope.$on('$translatePartialLoaderStructureChanged', function () {
            $translate.refresh();
        });
        
        $rootScope.$on('$stateChangeSuccess', function () {
            $translate.use(tmhDynamicLocale.get());
            $translate.fallbackLanguage(['es', 'en']);
            $translate.refresh();
        });
    }
})();
