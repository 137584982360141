(function() {
    'use strict';


    AgreementsTreatmentController.$inject = ['$scope'];

    function AgreementsTreatmentController($scope) {
        var vm = this; //jshint ignore:line
        vm.save = save;
        vm.isSaving = false;

        init();

        function init() {
            if (vm.agreement) {
                if (!vm.agreement.dateSigned) {
                    vm.agreement.dateSigned = new Date(); // default dateSigned is today
                }
            }
        }

        function save() {
            vm.isSaving = true;
            agreementsEmit();
        }

        function agreementsEmit() {
            vm.onChange({agreement: vm.agreement ? vm.agreement : null});
        }

    }

    angular.module('ipmApp').component('agreementsTreatment', {
        bindings: {
            agreement: '<',
            onChange: '&'
        },
        templateUrl: 'app/agreements-workflow/agreements-treatment/agreements-treatment.html',
        controller: AgreementsTreatmentController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    });
})();
