(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HaqEvaluationSkipsRemoveController', HaqEvaluationSkipsRemoveController);

    HaqEvaluationSkipsRemoveController.$inject = ['$uibModalInstance', 'entity', 'HaqEvaluationSkipsService'];

    function HaqEvaluationSkipsRemoveController($uibModalInstance, entity, HaqEvaluationSkipsService) {
        var vm = this;
        vm.haqEvaluationSkip = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.confirmDelete = function () {
            if (!vm.haqEvaluationSkip || !vm.haqEvaluationSkip.id) {
                return;
            }
            HaqEvaluationSkipsService.removeHaqEvaluationSkip(vm.haqEvaluationSkip.id).then(function (res) {
                $uibModalInstance.close(res);
            });
        };
    }
})();
