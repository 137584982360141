(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HaqEvaluationMultipleSkipsAddController', HaqEvaluationMultipleSkipsAddController);

    HaqEvaluationMultipleSkipsAddController.$inject = ['$stateParams', '$state', '$uibModalInstance', 'haqSkipsSelectedRows', 'HaqEvaluationSkipsService', '$scope'];

    function HaqEvaluationMultipleSkipsAddController($stateParams, $state, $uibModalInstance, haqSkipsSelectedRows, HaqEvaluationSkipsService, $scope) {
        var vm = this;
        vm.dateAddedBack = null;
        vm.pickToday = false;
        vm.haqSkipsSelectedRows = haqSkipsSelectedRows || [];

        vm.clear = clear;
        vm.save = save;
        vm.isSaving = false;
        vm.isInValidDate = false;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateAddedBack = false;

        vm.datePickerOptions = {
            minDate: new Date()
        };

        init();

        function init() {
        }

        vm.openCalendar = function (date) {
            vm.datePickerOpenStatus[date] = true;
        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            if (!vm.dateAddedBack || !vm.haqSkipsSelectedRows.length) return;
            vm.isSaving = true;
            var saveData = vm.haqSkipsSelectedRows.map(function (item) {
                return {
                    id: item.id,
                    dateAddedBack: vm.dateAddedBack,
                    pickToday: vm.pickToday
                };
            });
            HaqEvaluationSkipsService.addBackMultipleHaqEvaluationSkip(saveData)
                .then(function (res) {
                    $uibModalInstance.close(res);
                })
                .finally(function () {
                    vm.isSaving = false;
                });
        }

        $scope.$watch("vm.dateAddedBack", function (newValue) {
            if (!newValue) return;

            var currentDate = moment().startOf('day');
            var selectedDate = moment(newValue).startOf('day');

            vm.pickToday = !selectedDate.isAfter(currentDate);
            vm.isInValidDate = selectedDate.isBefore(currentDate);
            vm.isSaving = false;
        });
    }
})();
