
(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HaqEvaluationSkipsAddController', HaqEvaluationSkipsAddController);

    HaqEvaluationSkipsAddController.$inject = ['$stateParams', '$state', '$uibModalInstance','entity', 'HaqEvaluationSkipsService', '$scope'];

    function HaqEvaluationSkipsAddController ($stateParams, $state, $uibModalInstance, entity, HaqEvaluationSkipsService, $scope) {
        var vm = this;
        vm.haqEvaluationSkip = {
            id: entity.id,
            dateAddedBack: entity.dateAddedBack,
            pickToday: false
        };
        vm.clear = clear;
        vm.save = save;
        vm.isSaving = false;
        vm.isInValidDate = false;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateAddedBack = false;
            
        vm.datePickerOptions = {
            minDate: new Date()
        };

        init();

        function init() {
        }

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.haqEvaluationSkip && vm.haqEvaluationSkip.id !== null) {
                HaqEvaluationSkipsService.addBackHaqEvaluationSkip(vm.haqEvaluationSkip).then(function (res) {
                    vm.isSaving = false;
                    $uibModalInstance.close(res);
                });
            }
        }

        $scope.$watch("vm.haqEvaluationSkip.dateAddedBack", function(newValue, oldValue) {
            var currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (!vm.haqEvaluationSkip.dateAddedBack) { return; }
            vm.haqEvaluationSkip.pickToday = vm.haqEvaluationSkip.dateAddedBack > currentDate ? false : true;
            vm.isInValidDate = vm.haqEvaluationSkip.dateAddedBack < currentDate ? true : false;
            vm.isSaving = false;
            return;
        });
    }
})();
