(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HaqEvaluationSkipsController', HaqEvaluationSkipsController);

    HaqEvaluationSkipsController.$inject = ['$scope', '$state', 'HaqEvaluationSkipsService', 'ipmConstants', '$uibModal', '$uibModalStack'];

    function HaqEvaluationSkipsController ($scope, $state, HaqEvaluationSkipsService, constants, $uibModal, $uibModalStack) {
        var vm = this;
        vm.haqSkipsSelectedRows = []; // Allows multiple selection haqSkips
        vm.haqTracks = [];
        vm.haqSkips = [];
        vm.totalItems = 0;
        vm.pageSize = constants.PAGE_SIZE;
        vm.page = 1;
        vm.predicate = 'dateSkipped';
        vm.reverse = false;
        vm.hideRemoved = true;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.onChangeShowRemovedTests = onChangeShowRemovedTests;
        vm.showReasonDialog = showReasonDialog;

        init();

        function init() {
            vm.pageableAP = {
                page: 1,
                size: constants.PAGE_SIZE,
                totalItems: 0
            };
            resetHaqSkipSelectedRows(); // reset haqSkips Selected Rows on initialization
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.pageableAP.page > 0 ? vm.pageableAP.page - 1 : vm.pageableAP.page,
                size: vm.pageSize,
                sort: sort()
            };

            var skipFilter = {
                hideRemoved: vm.hideRemoved
            };
            HaqEvaluationSkipsService.findAll(skipFilter, pageable).then(function (response) {
                vm.pageableAP.totalItems = response && response.totalItems ? response.totalItems : 0;
                vm.haqSkips = response && response.data ? response.data : [];
                _.forEach(vm.haqSkips, function(o) {
                        o.reasonUi = o.reason;
                        o.reasonView = false;
                    if (o.reason && o.reason.length > 14) {
                        o.reasonUi = o.reason.slice(0, 10) + " ...";
                        o.reasonView = true;
                    }
                });
            });
        };

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            if (vm.predicate !== 'id') {
                result.push('id' + ',' + (vm.reverse ? 'desc' : 'asc'));
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.loadAll();
        }

        function onChangeShowRemovedTests() {
            vm.hideRemoved = !vm.hideRemoved;
        	vm.loadAll();
            resetHaqSkipSelectedRows();
        }

        function resetHaqSkipSelectedRows() {
            vm.haqSkipsSelectedRows = [];
        }

        vm.paginationOnChange = function (page) {
            vm.loadPage(page);
            resetHaqSkipSelectedRows();
        };

        vm.updateSelectionHaqSkip = function (row) {
            if (!row || !row.id) return;
            // Find the index of the selected row in the array
            var index = _.findIndex(vm.haqSkipsSelectedRows, function (selectedRow) {
                return selectedRow && selectedRow.id === row.id;
            });

            if (index === -1) {
                // If the row is not in the selection, mark it as selected and add to the list
                row.selected = true;
                vm.haqSkipsSelectedRows.push(row);
            } else {
                // If the row is already selected, remove it from the list and unmark as selected
                row.selected = false;
                vm.haqSkipsSelectedRows.splice(index, 1);
            }
        };

        vm.onClickAddBackMultiselect = function () {
            if (!vm.haqSkipsSelectedRows || vm.haqSkipsSelectedRows.length === 0) {
                return;
            }
            openDialogAddBackMultiple();
        };

        vm.onClickRemoveMultiselect = function () {
            if (!vm.haqSkipsSelectedRows || vm.haqSkipsSelectedRows.length === 0) {
                return;
            }
            openDialogRemoveMultiple();
        };

        vm.onClickAddBack = function (haqSkip) {
            if (!haqSkip || !haqSkip.id || haqSkip.selected || haqSkip.action) {
                return;
            }
            openDialogAddBack(haqSkip);
        };

        vm.onClickRemove = function (haqSkip) {
            if (!haqSkip || !haqSkip.id || haqSkip.selected || haqSkip.action) {
                return;
            }
            openDialogRemove(haqSkip);
        };

        function openDialogAddBack(haqSkip) {
            $uibModal.open({
                templateUrl: 'app/entities/haq-evaluation-skips/haq-evaluation-skips-add-dialog.html',
                controller: 'HaqEvaluationSkipsAddController',
                controllerAs: 'vm',
                size: 'md',
                windowClass: 'center-dialog-message vertical-center',
                backdrop: 'static',
                resolve: {
                    entity: function () {
                        return haqSkip;
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    resetHaqSkipSelectedRows();
                    vm.loadAll();
                }
            });
        }

        function openDialogRemove(haqSkip) {
            $uibModal.open({
                templateUrl: 'app/entities/haq-evaluation-skips/haq-evaluation-skips-remove-dialog.html',
                controller: 'HaqEvaluationSkipsRemoveController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'center-dialog-message vertical-center',
                backdrop: 'static',
                resolve: {
                    entity: function () {
                        return haqSkip;
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    resetHaqSkipSelectedRows();
                    vm.loadAll();
                }
            });
        }

        function openDialogAddBackMultiple() {
            $uibModal.open({
                templateUrl: 'app/entities/haq-evaluation-skips/haq-evaluation-multiple-skips-add-dialog.html',
                controller: 'HaqEvaluationMultipleSkipsAddController',
                controllerAs: 'vm',
                size: 'md',
                windowClass: 'center-dialog-message vertical-center',
                backdrop: 'static',
                resolve: {
                    haqSkipsSelectedRows: function () {
                        return vm.haqSkipsSelectedRows;
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    resetHaqSkipSelectedRows();
                    vm.loadAll();
                }
            });
        }

        function openDialogRemoveMultiple() {
            $uibModal.open({
                templateUrl: 'app/entities/haq-evaluation-skips/haq-evaluation-skips-multiple-remove-dialog.html',
                controller: 'HaqEvaluationSkipsMultipleRemoveController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'center-dialog-message vertical-center',
                backdrop: 'static',
                resolve: {
                    haqSkipsSelectedRows: function () {
                        return vm.haqSkipsSelectedRows;
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    resetHaqSkipSelectedRows();
                    vm.loadAll();
                }
            });
        }

        function showReasonDialog(reason) {
        	$uibModal.open({
                controller: function ($uibModalInstance) {
                	var vm = this;
                	vm.reason = reason;
                	vm.close = function() {
                		$uibModalInstance.dismiss('cancel');
                	}
                },
                templateUrl: 'app/entities/haq-evaluation-skips/skip-reason-dialog.html',
                controllerAs: 'vm',
                size: 'sm',
                backdrop: 'static',
                windowClass: 'absolute-center warning-dialog'
            });
        };

        $scope.$on('$destroy', function() {
            $uibModalStack.dismissAll();
        });
    }
})();
