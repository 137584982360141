(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HaqEvaluationSkipsMultipleRemoveController', HaqEvaluationSkipsMultipleRemoveController);

    HaqEvaluationSkipsMultipleRemoveController.$inject = ['$uibModalInstance', 'haqSkipsSelectedRows', 'HaqEvaluationSkipsService'];

    function HaqEvaluationSkipsMultipleRemoveController($uibModalInstance, haqSkipsSelectedRows, HaqEvaluationSkipsService) {
        var vm = this;
        vm.haqSkipsSelectedRows = haqSkipsSelectedRows || [];
        vm.isSaving = false;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.confirmDelete = function () {
            if (!vm.haqSkipsSelectedRows.length) return;
            vm.isSaving = true;
            var saveData = vm.haqSkipsSelectedRows.map(function (item) {
                return {
                    id: item.id,
                    dateAddedBack: item.dateAddedBack,
                    pickToday: item.pickToday
                };
            });
            HaqEvaluationSkipsService.removeMultipleHaqEvaluationSkip(saveData)
                .then(function (res) {
                    $uibModalInstance.close(res);
                })
                .finally(function () {
                    vm.isSaving = false;
                });
        };
    }
})();
