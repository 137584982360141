(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('AgreementsWorkflowController', AgreementsWorkflowController);

    AgreementsWorkflowController.$inject = ['$scope', 'ipmConstants', '$stateParams', 'AgreementsWorkflowService', '$window', 'ClearLocalStorageService', 'Auth', '$rootScope', '$state', 'NPQQuestionnaireService'];

    function AgreementsWorkflowController($scope, constants, $stateParams, AgreementsWorkflowService, $window, ClearLocalStorageService, Auth, $rootScope, $state, NPQQuestionnaireService) {
        var vm = this;
        vm.agreement = null;
        vm.agreementsStepCurrent = null;
        vm.onChangeAgreement = onChangeAgreement;
        vm.percentProgress = 0;
        vm.isSaving = false;

        vm.AGREEMENT_ACKNOWLEDGMENT = constants.AGREEMENT_ACKNOWLEDGMENT;
        vm.AGREEMENT_TRANSFER_MEDICAL = constants.AGREEMENT_TRANSFER_MEDICAL;
        vm.AGREEMENT_PRIVACY_PRACTICES = constants.AGREEMENT_PRIVACY_PRACTICES;
        vm.AGREEMENT_TREATMENT = constants.AGREEMENT_TREATMENT;
        vm.AGREEMENT_CONTINUITY_OF_CARE = constants.AGREEMENT_CONTINUITY_OF_CARE;


        vm.isSignatureStep = isSignatureStep;


        init();

        function init() {
            initAgreementsWorkflow();

            // checking patient status
            NPQQuestionnaireService.checkingStatusPatient().then(function(res) {
                if (res) {
                    if (res.isFullyComplete && res.isHaveOpenHAQ) {
                        vm.isHaveOpenHAQ = true;
                    }
                }
            });
        }

        function initAgreementsWorkflow() {
            AgreementsWorkflowService.getAgreementByPatientId().then(function (response) {
                vm.agreement = response && response.data ? response.data : null;

                vm.isOnlyDefaultAgreement = vm.agreement.isOnlyDefault; // detect only-default or with coc

                setPercentProgress(vm.agreement);
                setAgreementsStepCurrent();
            }).catch(function () {
                vm.success = null;
                vm.error = 'ERROR';
                $state.go('error');
            });
        }

        function setAgreementsStepCurrent() {
            if (vm.agreement) {

                if (vm.agreement.isFinishAllAgreement) {
                    goToLandingPage();
                    return;
                }

                if (vm.agreement.agreementName) {
                    vm.agreementsStepCurrent = vm.agreement.agreementName;
                } else {
                    vm.agreementsStepCurrent = constants.AGREEMENTS_STEP_SIGNATURE;
                }
            }
        }

        function setAgreement(response) {
            vm.agreement = response ? response : null;
        }

        function onChangeAgreement(agreement) {
            if (agreement) {
                if (vm.agreementsStepCurrent === constants.AGREEMENTS_STEP_SIGNATURE) {
                    // start save save SignatureAgreement
                    saveSignatureAgreement(agreement);
                } else {
                    // start save save Agreement
                    saveAgreement(agreement);
                }
            }
        }

        function setPercentProgress(agreement) {
            vm.percentProgress = agreement && agreement.percentProgress ? agreement.percentProgress : 0;
        }

        function saveSignatureAgreement(agreement) {
            vm.isSaving = true;
            AgreementsWorkflowService.finalSignatureAgreementWorkflow(agreement).then(function(res) {
                onSaveSuccess(res);
            }, function errorSave(error) {
                onSaveError(error);
            });

        }

        function saveAgreement(agreement) {
            vm.isSaving = true;
            AgreementsWorkflowService.saveAgreementWorkflow(agreement).then(function(res) {
                onSaveSuccess(res);
            }, function errorSave(error) {
                onSaveError(error);
            });

        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            setAgreement(result);
            setPercentProgress(vm.agreement);
            setAgreementsStepCurrent();
            scrollToTop();
        }

        function onSaveError(error) {
            vm.isSaving = false;
        }

        function scrollToTop() {
            $window.scrollTo(0, 0);
        }

        function isSignatureStep() {
            return vm.agreementsStepCurrent === constants.AGREEMENTS_STEP_SIGNATURE;
        }

        function goToLandingPage() {
            ClearLocalStorageService.clearAllAndWorkflow();
            ClearLocalStorageService.clearLandingPage();
            $state.go('landing-page');
        }
    }

})();
