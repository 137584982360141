'use strict';

angular.module('ipmApp')
.constant('ipmConstants', {

	// roles constant
	"ROLE_ADMIN" : "ROLE_ADMIN",
	"ROLE_STAFF" : "ROLE_STAFF",
	"ROLE_PATIENT" : "ROLE_PATIENT",
	"ROLE_MANAGER" : "ROLE_MANAGER",
    "ROLE_QHP" : "ROLE_QHP",
    "ROLE_BILLER" : "ROLE_BILLER",

    "BATTERY_NOT_COMPLETE" : "batteryNotComplete",
	"BATTERY_COMPLETE": "batteryComplete",

	// event name using $broadcast
	"EVT_AUTHENTICATION_SUCCESS": "authenticationSuccess",
	"EVT_AUTHENTICATION_SUCCESS_BY_INVITATION": "authenticationSuccessByInvitation",
	"EVT_LOG_OUT": "eventLogOut",

	//Test constant
	"PHQ_9": "Patient Health Questionnaire (PHQ-9)",

	//Question contanst
	"SUICIDE_RISK_TEST": "Thoughts that you would be better off dead, or of hurting yourself",

	//No wait constants
	"NO_WAIT": "+NOWAIT",

	"CSV_EXT": ".csv",

	"PASS_RESTRICTION": "passRestriction",

    "SURVEY_RESULT_ID": "surveyResultId",

    "LAUNCH_FROM": "launchFrom",

    "QUESTION_ID_EVALUATION_WORKFLOW": "questionId",

    "ACTION_EVALUATION_WORKFLOW": "actionEvaluationWorkflow",

    "HAQ": "HAQ",

    "HAQ_ADHOC": "HAQ_ADHOC",

    "NPQ_QUESTIONNAIRE_ID": "npqQuestionnaireId",

	"EMAIL_PATTERN": "/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i",

    "SEARCH_TYPE_HAQ": "haq",

	"INVITATION_WORKFLOW": "invitationWorkflow",

	"INVITATION_SECRET_KEY": "invitationSecretKey",

    // Agreements type
    "AGREEMENT_ACKNOWLEDGMENT" : "ACKNOWLEDGMENT",
    "AGREEMENT_TRANSFER_MEDICAL" : "AUTHORIZATION_TO_TRANSFER_MEDICAL_RECORDS",
    "AGREEMENT_PRIVACY_PRACTICES" : "NOTICE_OF_PRIVACY_PRACTICES",
    // "AGREEMENT_OPIOID_MONOGRAPH_AND_NARCOTICS" : "OPIOID_MONOGRAPH_AND_NARCOTICS_AGREEMENT",
    // "AGREEMENT_RISK_OF_OPIOIDS" : "RISK_OF_OPIOIDS",
    "AGREEMENT_TREATMENT" : "TREATMENT",
    "AGREEMENT_CONTINUITY_OF_CARE" : "CONTINUITY_OF_CARE",

    // Agreements Step
    "AGREEMENTS_STEP_SIGNATURE" : "AGREEMENTS_STEP_SIGNATURE",

    // LandingPage
    "PASS_LANDING_PAGE" : "PASS_LANDING_PAGE",
    "KEEP_LANDING_PAGE" : "KEEP_LANDING_PAGE",

    // SURVEY_WORKFLOW_STATE
    "SURVEY_WORKFLOW_STATE": ['home', 'survey-workflow', 'survey-workflow-thankyou'],

    "PAGE_SIZE_MAX": 1000,
    "PAGE_SIZE": 20,

    // timer
    "TIME_SPENT": "TIME_SPENT",
    "TIMER_HAS_ELAPSED": "TIMER_HAS_ELAPSED",
    "TIME_ELAPSED_DEFAULT" : 31,

    // QHP Task
    "HAQ_EVALUATION": "HAQ_EVALUATION",
    "HAQ_BRIEF_REVIEW": "HAQ_BRIEF_REVIEW",
    "CARE_PLAN_REVIEW": "CARE_PLAN_REVIEW",
    "INSTRUCTOR": "INSTRUCTOR",
    "FRP_MDE": "FRP_MDE",
    "CONTINUITY_OF_CARE" :"CONTINUITY_OF_CARE",

    // CarePlanReviewStatus
    "CARE_PLAN_REVIEW_STATUS" : ['ALL', 'NOT_READY', 'NOT_STARTED', 'STARTED', 'SKIPPED'],

    // CPR
    "PATIENT_ID_FOR_CPR_WORKFLOW": "PATIENT_ID_FOR_CPR_WORKFLOW",
    "DAYS_PREVIOUS_CPR_COMPLETED": 31,
    "TREATMENT_HISTORY_TYPE": ['PHYSICAL_THERAPY', 'ACUPUNCTURE', 'CHIRO', 'PSYCH', 'MDE', 'FRP', 'INJECTIONS'],
    "CASE_STATUSES": ['TTD', 'PS_FUTURE_MEDICAL', 'PS_CR', 'NA_CASE_CLOSED'],
    "WORK_STATUSES": ['FT_UNMODIFIED', 'FT_MODIFIED', 'PT_UNMODIFIED', 'PT_MODIFIED', 'NOT_WORKING', 'RETIRED', 'NA_CASE_CLOSED'],
    "STATUS_INDICATORS": ['CONSERVATIVE_CARE', 'INTERVENTIONS', 'EVALUATION_MDE', 'PAIN_PROGRAM'],
    "CPR_MAP_KEY_VALUES": ['treatment_history_table', 'surgical_candidacy_status'],
    "TIME_SPENT_CPR_WORKFLOW": "TIME_SPENT_CPR_WORKFLOW",
    "TIME_CPR_LOGIN": "TIME_CPR_LOGIN",
    "INIT_DATA_CPR": "INIT_DATA_CPR",
    // Call Log
    "PARTY_TYPES": ['PATIENT', 'ATTORNEY', 'NCM', 'ADJUSTER', 'UTILIZATION_REVIEW', 'OTHER'],
    // MaximumNumberRegister
    "MAXIMUM_NUMBER_REGISTER": 8,
    // Wellness Class
    "CURRENT_SLIDE_WC": "CURRENT_SLIDE_ID_WC",
    "CURRENT_PAGE_PDF_VIEW": "CURRENT_PAGE_PDF_VIEW",
    "SCALE_ZOOM_IN": "SCALE_ZOOM_IN",
    "SCALE_ZOOM_OUT": "SCALE_ZOOM_OUT",
    "PRESENTATION_VIEW": "PRESENTATION_VIEW",
    "EVT_CURRENT_SLIDE_WC": "currentSlideWCEventChange",
    "EVT_BACK_SLIDE_WC": "backSlideWCEventChange",
    "EVT_NEXT_SLIDE_WC": "nextSlideWCEventChange",
    "EVT_ZOOM_IN_SLIDE_WC":"zoomInSlideWCEventChange",
    "EVT_ZOOM_OUT_SLIDE_WC":"zoomOutSlideWCEventChange",
    "EVT_GO_TO_PAGE_SLIDE_WC":"goToPageSlideWCEventChange",
    "PDF_DEFAULT_SCALE": 1.3,
    // All Course Registration Step
    "COURSE_REGISTRATION_STEPS": ['SUGGEST_COURSE', 'INSTRUCTION_DETAIL_LIST', 'CLASS_SESSION_DETAIL_LIST'],
    // MDE statuss
    "MDE_STATUS": ['MDE_PENDING','MDE_IN_PROGRESS', 'MDE_COMPLETED_RECOMMENDED', 'MDE_COMPLETED_REQUESTED', 'MDE_COMPLETED_AUTHORIZED', 'MDE_ABANDONED', 'MDE_COMPLETED_NOT_RECOMMENDED' ],
    "MDE_REPORT_STATUS": {'START_REPORT':'START_REPORT','EDIT_REPORT':'EDIT_REPORT','VIEW_REPORT':'VIEW_REPORT'},
    // FRP status
    "FRP_STATUS_FILTER": ['PENDING_MDE','FRP_REQUESTED_PENDING', 'FRP_ENROLLED','FRP_ACTIVE_HOLD'],
    "FRP_STATUS": ['PENDING_MDE', 'FRP_REQUESTED_PENDING', 'FRP_ENROLLED', 'FRP_GRADUATED', 'FRP_EARLY_DISCHARGE', 'FRP_ACTIVE_HOLD', 'FRP_INACTIVE_HOLD'],
    "INACTIVE_PATIENTS_STATUS": ['FRP_GRADUATED','FRP_EARLY_DISCHARGE', 'FRP_INACTIVE_HOLD','MDE_COMPLETED_NOT_RECOMMENDED','MDE_ABANDONED'],
    // attendanceTrackers
    "ATTENDANCE_TRACKERS": ['1','2','3','4','5', '6', '7'], // 1-7 where 1 is Saturday and 7 is Friday
    "STATES_OF_AMERICA": ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY' ,'LA','ME','MD','MA','MI','MN','MS','MO','MT','NE'
		,'NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR' ,'PA','RI','SC','SD','TN','TX','UT','VT','VA','WA' ,'WV','WI','WY','AS','GU','MP','PR','VI','DC'],
    "ADD_ENROLL_SUCCESS": "addEnrollSuccess",
    // use to hidden Wellness Classes function
    "HIDE_WELLNESS_CLASSES": true,
    // use to disable state WELLNESS_CLASSES
    "ROLE_DISABLE_WELLNESS_CLASSES": "ROLE_DISABLE_WELLNESS_CLASSES",
    "FRP_REPORT_STATUS_VIEW": {'EDIT_REPORT': 'EDIT_REPORT', 'FRP_REPORT_PREVIOUS_NOT_FINALIZED': 'FRP_REPORT_PREVIOUS_NOT_FINALIZED', 'VIEW_REPORT': 'VIEW_REPORT', 'ADD_NEW_REPORT': 'ADD_NEW_REPORT'},
    "FRP_REPORT_TYPE" : {FIRST_REPORT: 'FIRST_REPORT', WEEKLY_REPORT: 'WEEKLY_REPORT', MID_SESSION_REPORT: 'MID_SESSION_REPORT', DISCHARGE_REPORT: 'DISCHARGE_REPORT', OTHER_REPORT: 'OTHER_REPORT'},
    "FRP_REPORT_TYPE_2": ['FIRST_REPORT', 'WEEKLY_REPORT', 'MID_SESSION_REPORT', 'DISCHARGE_REPORT', 'OTHER_REPORT'],
    "LECTURES": ['FIRST', 'SECOND', 'THREE', 'FOUR', 'FIVE'],
    // Template
    "FRP_MDE_TEMPLATE": {
        "USER": "USER",
        "GLOBAL": "GLOBAL",
        "MDE_MEDIAL": ['HISTORY_PRESENT_ILLNESS','MECHANISM_INJURY','PRESENT_COMPLAINTS','CONSERVATIVE_CARE','SURGICAL_HISTORY', 'SUMMARY_AND_RECOMMENDATIONS'],
        "HISTORY_PRESENT_ILLNESS": "HISTORY_PRESENT_ILLNESS",
        "MECHANISM_INJURY": "MECHANISM_INJURY",
        "PRESENT_COMPLAINTS": "PRESENT_COMPLAINTS",
        "CONSERVATIVE_CARE": "CONSERVATIVE_CARE",
        "SURGICAL_HISTORY": "SURGICAL_HISTORY",
        "MDE_PT": ['SUBJECTIVE_COMPLAINTS', 'PHYSICAL_FUNCTIONAL_NARRATIVE'],
        "SUBJECTIVE_COMPLAINTS_PSYCH": "SUBJECTIVE_COMPLAINTS_PSYCH",
        "PYSCHOSOCIAL_HISTORY":"PYSCHOSOCIAL_HISTORY",
        "MDE_PSYCH": ['SUBJECTIVE_COMPLAINTS_PSYCH', 'PYSCHOSOCIAL_HISTORY','MENTAL_STATUS', 'PSYCHOLOGICAL_GOALS_OF_TREATMENT', 'SUMMARY_AND_IMPRESSIONS'],
        "SUBJECTIVE_COMPLAINTS": "SUBJECTIVE_COMPLAINTS",
        "MENTAL_STATUS": "MENTAL_STATUS",
        "FRP_PSYCH": ['SUBJECTIVE','PSYCHOLOGICAL_NARRATIVE'],
        "SUBJECTIVE": "SUBJECTIVE",
        "PSYCHOLOGICAL_NARRATIVE": "PSYCHOLOGICAL_NARRATIVE",
        "FRP_PT": ['PHYSICAL_SUBJECTIVE','PHYSICAL_FUNCTIONAL_NARRATIVE'],
        "PHYSICAL_SUBJECTIVE": "PHYSICAL_SUBJECTIVE",
        "PHYSICAL_FUNCTIONAL_NARRATIVE": "PHYSICAL_FUNCTIONAL_NARRATIVE",
        "FRP_REPORT_CONCLUSION": ["TREATMENT_TEAM_OBSERVATION"],
        "TREATMENT_TEAM_OBSERVATION": "TREATMENT_TEAM_OBSERVATION",
        "FRP_REPORT_ADMIN": ["REPORT_TYPE"],
        "REPORT_TYPE": "REPORT_TYPE",
        "PSYCHOLOGICAL_GOALS_OF_TREATMENT": "PSYCHOLOGICAL_GOALS_OF_TREATMENT",
        "SUMMARY_AND_IMPRESSIONS": "SUMMARY_AND_IMPRESSIONS",
        "SUMMARY_AND_RECOMMENDATIONS": "SUMMARY_AND_RECOMMENDATIONS",
        "MDE_CONCLUSION": ["CONCLUSION"],
        "CONCLUSION": "CONCLUSION",
        "GENERAL": "GENERAL"
    },
    "FRP_PROGRAM": "FRP_PROGRAM",
    "FRP_PROGRAMS_ERROR": "FRP_PROGRAMS_ERROR",
    "SEVERITY": {
        "TSK":["MINIMAL", "MILD", "MODERATE", "SEVERE"],
        "LEFS": ["EXTREME_DIF", "SEVERE", "MODERATE", "MILD", "NO_DIFFICULTY"],
        "NDI": ["NO_DISABILITY", "MIN", "MOD", "SEVERE", "COMPLETE"],
        "Oswestry": ["MINIMUM", "MOD", "SEVERE", "CRIPPLED", "BED_BND"],
        "PSEQ":["NOT_CONFIDENT", "MILD", "MOD", "COMPLETE", "TO_FUNCT_WITH_PAIN"],
        "QuickDash":["NO_DISABILITY", "MINIMUM", "MILD", "MODERATE", "SEVERE"],
        "ADL": ["INTERPRETATION", "MILD_DISABILITY", "MODERATE_DISABILITY", "SEVERE_DISABILITY"]
    },
    "FRP_PSYCH_SEVERITY": {
	    "subjectives": {
            "CES_D":[
                { "key": "WNL", "name": "WNL", "value": "0-15 (WNL)", "minRange":0, "maxRange": 15 },
                { "key": "MILD", "name": "Mild", "value": "16-25 (Mild)", "minRange":16, "maxRange": 25 },
                { "key": "MODERATE", "name": "Moderate", "value": "26-35 (Moderate)", "minRange":26, "maxRange": 35 },
                { "key": "SEVERE", "name": "Severe", "value": "36+ (Severe)", "minRange":36, "maxRange": 1000 }
            ],
            "GAD_7": [
                { "key": "MILD", "name": "Mild", "value": "0-6 (Mild)", "minRange":0, "maxRange": 6 },
                { "key": "MODERATE", "name": "Moderate", "value": "7-14 (Moderate)", "minRange":7, "maxRange": 14 },
                { "key": "SEVERE", "name": "Severe", "value": "15+ (Severe)", "minRange":15, "maxRange": 1000 }
            ],
            "BPI": [
                { "key": "WNL", "name": "WNL", "value": "0-3 (WNL)", "minRange":0, "maxRange": 3 },
                { "key": "MILD", "name": "Mild", "value": "4-5 (Mild)", "minRange":4, "maxRange": 5 },
                { "key": "MODERATE", "name": "Moderate", "value": "6-7 (Moderate)", "minRange":6, "maxRange": 7 },
                { "key": "SEVERE", "name": "Severe", "value": "8-10 (Severe)", "minRange":8, "maxRange": 10 }
            ],
            "ISI": [
                { "key": "WNL", "name": "WNL", "value": "0-7 (WNL)", "minRange":0, "maxRange": 7 },
                { "key": "MILD", "name": "Mild", "value": "8-14 (Mild)", "minRange":8, "maxRange": 14 },
                { "key": "MODERATE", "name": "Moderate", "value": "15-21 (Moderate)", "minRange":15, "maxRange": 21 },
                { "key": "SEVERE", "name": "Severe", "value": "22-28 (Severe)", "minRange":22, "maxRange": 28 }
            ],
            "TSK": [
                { "key": "MINIMAL", "name": "Minimal", "value": "0-29 (Minimal)", "minRange":0, "maxRange": 29},
                { "key": "MILD", "name": "Mild", "value": "30-42 (Mild)", "minRange":30, "maxRange": 42},
                { "key": "MODERATE", "name": "Moderate", "value": "43-55 (Moderate)", "minRange":43, "maxRange": 55 },
                { "key": "SEVERE", "name": "Severe", "value": "56-68 (Severe)", "minRange":56, "maxRange": 68 }
            ],
            "PHQ_9": [
                { "key": "NONE", "name": "None", "value": "0-4 (None)", "minRange":0, "maxRange": 4 },
                { "key": "MILD", "name": "Mild", "value": "5-9 (Mild)", "minRange":5, "maxRange": 9 },
                { "key": "MODERATE", "name": "Moderate", "value": "10-14 (Moderate)", "minRange":10, "maxRange": 14 },
                { "key": "MODERATELY_SEVERE", "name": "Moderately Severe", "value": "15-19 (Moderately Severe)", "minRange":15, "maxRange": 19 },
                { "key": "SEVERE", "name": "Severe", "value": "20-27 (Severe)", "minRange":20, "maxRange": 27 }
            ]
        },
        "objectives": {
            "HAM_A": [
                { "key": "WNL", "name": "WNL", "value": "0-10 (WNL)", "minRange":0, "maxRange": 10 },
                { "key": "MILD_ANXIETY", "name": "Mild anxiety", "value": "11-17 (Mild anxiety)", "minRange":11, "maxRange": 17 },
                { "key": "MODERATE_ANXIETY", "name": "Moderate anxiety", "value": "18-24 (Moderate anxiety)", "minRange":18, "maxRange": 24 },
                { "key": "SEVERE_ANXIETY", "name": "Severe anxiety", "value": ">25 (Severe anxiety)","minRange":25, "maxRange": 1000 }
            ],
            "HAM_D": [
                { "key": "WNL", "name": "WNL", "value": "0-7 (WNL)", "minRange":0, "maxRange": 7 },
                { "key": "MILD_DEPRESSION", "name": "Mild depression", "value": "8-16 (Mild depression)", "minRange":8, "maxRange": 16  },
                { "key": "MODERATE_DEPRESSION", "name": "Moderate depression", "value": "17-23 (Moderate depression)", "minRange":17, "maxRange": 23 },
                { "key": "SEVERE_DEPRESSION", "name": "Severe depression", "value": ">24 (Severe depression)", "minRange":24, "maxRange": 1000 }
            ]
        }
    },
    "STATUS_FINAL_FRP_MDE": "FINALIZED",
    "HOURS_ATTENDED_CAN_NOT_EXCEED": 160, // default 160 hours
    "NUMBER_HRS_ATTENDED": 4, // Hours in attendance is number of days attended x 4hrs
    // TimeSpentType
    "TIME_SPENT_TYPE": {START_RUN_TIMESPENT: 'START_RUN_TIMESPENT', RESET_TIMESPENT_LOGIN: 'RESET_TIMESPENT_LOGIN', UPDATE_TIMESPENT: 'UPDATE_TIMESPENT', UPDATE_AND_RESET_TIMESPENT_LOGIN: 'UPDATE_AND_RESET_TIMESPENT_LOGIN'},
    "SKIP_REASON": ["CASH_PAY", "DECEASED", "CURRENTLY_IN_FRP", "NO_VISITS_COMPLETED", "NO_NEW_INFORMATION_TO_REVIEW", "STABLE_AND_P_AND_S", "SKIPPING_INDEFINITELY", "WAITING_ON_PTP", "OTHER"],
    "TOTAL_NPQ_SECTION": 12,
    //Wed Worker
    "WEB_WORKER": {
	    "START": "START",
        "CLOSE": "CLOSE",
        "START_TIME_SPENT": "START_TIME_SPENT",
        "START_LOGOFF_INTERVAL": "START_LOGOFF_INTERVAL",
        "RESET_LOGOFF_INTERVAL": "RESET_LOGOFF_INTERVAL",
        "CLEAR_LOGOFF_INTERVAL": "CLEAR_LOGOFF_INTERVAL",
        "START_NOTICING_LOGOFF_INTERVAL": "START_NOTICING_LOGOFF_INTERVAL",
        "RESET_NOTICING_LOGOFF_INTERVAL": "RESET_NOTICING_LOGOFF_INTERVAL",
        "CLEAR_NOTICING_LOGOFF_INTERVAL": "CLEAR_NOTICING_LOGOFF_INTERVAL",
        "START_TIME_SPENT_INTERVAL_EVL": "START_TIME_SPENT_INTERVAL_EVL",
        "RESET_TIME_SPENT_INTERVAL_EVL": "RESET_TIME_SPENT_INTERVAL_EVL",
        "CLEAR_TIME_SPENT_INTERVAL_EVL": "CLEAR_TIME_SPENT_INTERVAL_EVL",
        "START_AUTO_SAVE_INTERVAL_EVL": "START_AUTO_SAVE_INTERVAL_EVL",
        "RESET_AUTO_SAVE_INTERVAL_EVL": "RESET_AUTO_SAVE_INTERVAL_EVL",
        "CLEAR_AUTO_SAVE_INTERVAL_EVL": "CLEAR_AUTO_SAVE_INTERVAL_EVL"
    },
    "REFRESH": "REFRESH",

    "CASE_EXCLUSIONS_TYPES": [ 'HAQ', 'HAQ_EVAL', 'CPR', 'WELLNESS' ],

    "CASE_EXCLUSION_DETAIL_TYPES": [ 'HAQ_SERVICE', 'HAQ_BILLING', 'HAQ_EVAL_SERVICE', 'HAQ_EVAL_BILLING', 'CPR_SERVICE', 'CPR_BILLING', 'PNE_SERVICE' ],

    "CASE_EXCLUSIONS_MAP": {
        'HAQ_SERVICE': 'HAQ',
        'HAQ_BILLING': 'HAQ',
        'HAQ_EVAL_SERVICE': 'HAQ_EVAL',
        'HAQ_EVAL_BILLING': 'HAQ_EVAL',
        'CPR_SERVICE': 'CPR',
        'CPR_BILLING': 'CPR',
        'PNE_SERVICE': 'WELLNESS'
    },

    "CONTAINER_SUB_ACTION_MAPPING": {'CPR_042': ['CPR_043', 'CPR_044', 'CPR_045', 'CPR_046'], 'CPR_051': ['CPR_052', 'CPR_053', 'CPR_054']},
    "DISPLAY_OTHER_TEXT_FIELD_CODE": ['CPR_031', 'CPR_032', 'CPR_033'],
    "OPTION_ITEM_ID_REQUIRED": [16, 69, 70, 71, 72],
    "MINLENGTH_FREETEXT_REQUIRED_CODE": ['CPR_034', 'CPR_056', 'CPR_057', 'CPR_059'],
    "CHECKBOX_REQUIRED": ['CPR_031', 'CPR_032', 'CPR_033', 'CPR_042', 'CPR_043', 'CPR_044', 'CPR_045'],
    "DISPLAY_MESSAGE_REQUIRED_SUB_CHECKBOX": ['CPR_031', 'CPR_032', 'CPR_033', 'CPR_042', 'CPR_043', 'CPR_044', 'CPR_045', 'CPR_047', 'CPR_048', 'CPR_049', 'CPR_051', 'CPR_052', 'CPR_053', 'CPR_054', 'CPR_055', 'CPR_056', 'CPR_057'],
    "CONTAINER_CHECKBOX_CODE": ['CPR_042', 'CPR_051'],
    "HAVE_ANSWERS_CHECKBOX_QUESTION_CODE": ['CPR_031', 'CPR_032', 'CPR_033', 'CPR_047', 'CPR_048', 'CPR_049'],
    "DISPLAY_SCHEDULING_SUB_QUESTION_ACTION": ['CPR_042', 'CPR_043', 'CPR_044', 'CPR_045', 'CPR_046'],
    "DISPLAY_GATHER_SUB_QUESTION_ACTION": ['CPR_051', 'CPR_052', 'CPR_053', 'CPR_054'],
    "DISPLAY_SUB_QUESTION_ACTION": ['CPR_031', 'CPR_032', 'CPR_033', 'CPR_034', 'CPR_047', 'CPR_048', 'CPR_049', 'CPR_055', 'CPR_056', 'CPR_057', 'CPR_059'],
    "DISPLAY_LABEL_CODE": ['CPR_046', 'CPR_056', 'CPR_057'],

});
